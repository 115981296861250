import moment from 'moment';
import {
  offerDateToPaySelected,
  offerSelected,
  orderingInvoiceIssuedSelected,
  orderingOfferSelected,
  companyFilter,
  orderingSelected,
  preofferSelected,
  debtorCollectionPriorityFilter,
  updateVar,
  refreshToken as currentRefreshToken,
} from '.';
import { customMutation } from '../../helpers/custom-mutation';
import { REVOKE_REFRESH_TOKEN } from '../../graphql';

const setShowReferralCampaignSelectedCompany = (company) => {
  updateVar('showReferralCampaignSelectedCompany', company.showReferralCampaign);
};

const setSelectedFolio = (folio) => {
  updateVar('selectedFolio', folio);
};

const setSelectedCompanyRutFromExternalLogin = ({ actualCompanyRut }) => {
  updateVar('selectedCompanyRut', actualCompanyRut);
};

const cleanOfferShoppingCart = () => {
  offerSelected([]);
  offerDateToPaySelected({});
};

const cleanPreOfferShoppingCart = () => {
  preofferSelected([]);
};

const cleanOrderingShoppingCart = () => {
  orderingSelected([]);
  orderingInvoiceIssuedSelected({});
};

const cleanOrderingOfferShoppingCart = () => {
  orderingOfferSelected([]);
};

const cleanAllShopping = () => {
  cleanOfferShoppingCart();
  cleanOrderingShoppingCart();
  cleanPreOfferShoppingCart();
  cleanOrderingOfferShoppingCart();
};

const setDebtorCollectionPriorityFilter = (priority) => {
  debtorCollectionPriorityFilter(priority);
};

const setCompanyFilter = (newCompanyFilter) => {
  companyFilter(newCompanyFilter);
};

const verifyToken = ({ payload }) => {
  if (payload) {
    const delayOfExpire = 60;
    const difference = payload.exp - payload.origIat;
    const expires = moment().unix() + difference - delayOfExpire;
    updateVar('tokenExpire', expires);
  }
};

const logIn = ({ token, refreshToken, payload }) => {
  cleanAllShopping();
  updateVar('token', token);
  updateVar('refreshToken', refreshToken);
  updateVar('userEvents', []);
  verifyToken({ payload });
};
const logOut = (client) => {
  cleanAllShopping();
  if (currentRefreshToken()) {
    customMutation(REVOKE_REFRESH_TOKEN, { refreshToken: currentRefreshToken() });
  }
  updateVar('token', undefined);
  updateVar('refreshToken', undefined);
  updateVar('tokenExpire', undefined);
  if (client) {
    localStorage.removeItem('apollo-cache-persist');
    localStorage.removeItem('apolloVariables');
    const clientCacheData = client.cache.data;
    Object.keys(clientCacheData.data).forEach(
      (key) => !['ROOT_QUERY', 'CountryType:1', 'CountryType:2'].includes(key) && clientCacheData.delete(key),
    );
  }
};

export {
  logIn,
  logOut,
  setDebtorCollectionPriorityFilter,
  setShowReferralCampaignSelectedCompany,
  setSelectedFolio,
  setSelectedCompanyRutFromExternalLogin,
  setCompanyFilter,
  cleanOfferShoppingCart,
  cleanPreOfferShoppingCart,
  cleanOrderingShoppingCart,
  cleanOrderingOfferShoppingCart,
  cleanAllShopping,
  verifyToken,
};
