import { makeVar, useQuery } from '@apollo/client';

const oldUser = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
const ROOT = 'apolloVariables';
const getRoot = () => JSON.parse(localStorage.getItem(ROOT));
const setRoot = (root) => localStorage.setItem(ROOT, JSON.stringify(root));
const getVariable = (name) => getRoot() && getRoot()[name];
const token = makeVar(getVariable('token'));
const refreshToken = makeVar(getVariable('refreshToken'));
const tokenExpire = makeVar(getVariable('tokenExpire'));
const requestingPlatformIdVar = makeVar(getVariable('requestingPlatformIdVar'));
const selectedCompanyRut = makeVar(getVariable('selectedCompanyRut') || null);
const showReferralCampaignSelectedCompany = makeVar(getVariable('showReferralCampaignSelectedCompany'));
const userEvents = makeVar(getVariable('userEvents'));
const selectedFolio = makeVar();
const topMessage = makeVar(null);
const toggleSiiForm = makeVar({ open: false, autoFillCompany: false });
const mexicoAlert = makeVar({ open: false });
const preofferSelected = makeVar([]);
const offerSelected = makeVar([]);
const debtorCollectionPriorityFilter = makeVar(null);
const offerDateToPaySelected = makeVar({});
const companyFilter = makeVar({});
const orderingSelected = makeVar([]);
const orderingInvoiceIssuedSelected = makeVar({});
const orderingOfferSelected = makeVar([]);
const shoppingCartShow = makeVar(false);
const shoppingCartDialogVar = makeVar({});
const shoppingCartOffersVar = makeVar([]);
const FirstLoadOfInvoices = makeVar(false);
const popUpRecoveryPasswordDemoUser = makeVar(false);
const snackBarAlerts = makeVar([]);
const fingoRut = makeVar(77201556);

const updateVar = (name, value) => {
  const variables = {
    token,
    refreshToken,
    selectedCompanyRut,
    topMessage,
    toggleSiiForm,
    mexicoAlert,
    selectedFolio,
    requestingPlatformIdVar,
    showReferralCampaignSelectedCompany,
    userEvents,
    tokenExpire,
  };
  const newRoot = { ...getRoot(), [name]: value };
  setRoot(newRoot);
  variables[name](value);
};
if (oldUser) {
  updateVar('token', oldUser.token);
  updateVar('refreshToken', oldUser.refreshToken);
  const oldSelectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
  if (oldSelectedCompany) {
    updateVar('selectedCompanyRut', oldSelectedCompany.rut);
    updateVar('showReferralCampaignSelectedCompany', oldSelectedCompany.showReferralCampaign);
  }
  localStorage.removeItem('user');
  localStorage.removeItem('creditLine');
  localStorage.removeItem('registerComplete');
  localStorage.removeItem('debtorsCreditLnes');
  localStorage.removeItem('negativeBalance');
  localStorage.removeItem('debtorsExposition');
  localStorage.removeItem('commissionRules');
  localStorage.removeItem('selectedCompany');
}
const getReactiveVariablesMultipleQuery = (queryArray) => queryArray.reduce((acc, newQuery) => {
  const { data } = useQuery(newQuery);
  const entriesData = Object.entries(data);
  const key = entriesData[0][0];
  const value = entriesData[0][1];
  return { ...acc, [key]: value };
}, {});

export {
  token,
  refreshToken,
  tokenExpire,
  requestingPlatformIdVar,
  selectedCompanyRut,
  topMessage,
  toggleSiiForm,
  mexicoAlert,
  selectedFolio,
  showReferralCampaignSelectedCompany,
  updateVar,
  getReactiveVariablesMultipleQuery,
  userEvents,
  debtorCollectionPriorityFilter,
  popUpRecoveryPasswordDemoUser,
  preofferSelected,
  companyFilter,
  offerSelected,
  offerDateToPaySelected,
  orderingSelected,
  orderingInvoiceIssuedSelected,
  orderingOfferSelected,
  shoppingCartShow,
  shoppingCartDialogVar,
  shoppingCartOffersVar,
  FirstLoadOfInvoices,
  snackBarAlerts,
  fingoRut,
};
