/* eslint-disable react/prop-types */
import React from 'react';
import { Avatar, Icon, SvgIcon } from '@mui/material';
import { ReactComponent as SiiIconImage } from './sii_icon.svg';
import { ReactComponent as SatIconImage } from './sat_icon.svg';
import PencilIconImage from './pencil.svg';
import SantanderImage from './santander_image.png';
import FingoImage from './fingo_image.png';

const SiiIcon = (props) => {
  const { sx: { color } } = props;
  return (
    <SvgIcon {...props} alt="sii-icon">
      <SiiIconImage fill={color} />
    </SvgIcon>
  );
};

const SatIcon = (props) => {
  const { sx: { color } } = props;
  return (
    <SvgIcon {...props} alt="sat-icon">
      <SatIconImage fill={color} />
    </SvgIcon>
  );
};

const PencilIcon = (props) => (
  <Icon {...props}>
    <img src={PencilIconImage} alt="pencil-icon" />
  </Icon>
);

const SantanderIcon = () => (
  <Avatar
    src={SantanderImage}
    sx={{ width: 17, height: 17 }}
  />
);

const FingoIcon = () => (
  <Avatar
    src={FingoImage}
    sx={{ width: 17, height: 17 }}
    style={{ borderRadius: 0 }}
  />
);

const requestingPlatformMapperImage = {
  SANTANDER: SantanderImage,
  FINGO: FingoImage,
};

const requestingPlatformMapperIcon = {
  SANTANDER: SantanderIcon,
  FINGO: FingoIcon,
};

export { SiiIcon, SatIcon,
  PencilIcon, requestingPlatformMapperImage, requestingPlatformMapperIcon };
